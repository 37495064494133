import React, { FC, useContext } from "react";
import * as styles from "./SolutionsMain.module.scss";
import { Link } from "gatsby";
import buttonRight from "../../images/buttonArrowRight.svg";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleGreenBlur from "../Shared/CircleOutlineAndBlur/Circlegreenblur";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import { pathWithoutSpace } from "../ReportsUiComponents/ReportsMainHelpers";
import { ImageUrlCheck } from "../Shared/ImageUrlCheck/ImageUrlCheck";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import PagePagination from "../Shared/PagePagination/PagePagination";
import { LanguageContext } from "../../context/languageContext";
import translate from "../../i18n/translate";
import { StaticRouter } from "react-router-dom";

type Props = {
  data?: any;
  pageContext?: any;
};

const SolutionsMain: FC<Props> = ({ data, pageContext }) => {
  const { language } = useContext(LanguageContext);
  return (
    <InnerWrapper>
      <div className={styles.reportsContainerWrapper}>
        <div className={styles.reportsMainContainer}>
          <h1 className="heading">
            <span>.</span>Solutions
          </h1>
          <p> {translate("solutions-page-subtitle")}</p>
          <div className={styles.backgroundCircles}>
            <CircleOutline
              isBlurVisible={false}
              initialScale={0.3}
              animateScale={1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.contactOutlineCircle}
            />
            <CircleGreenBlur
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurGreen}
            />

            <CircleBlur
              initialOpacity={0}
              animateOpacity={0.1}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.aboutMainCircleBlurBlue}
            />
          </div>
        </div>

        <div className={styles.reportsMainArticles}>
          <div className={styles.reportsRow}>
            {data.allSanitySolutions.edges.map(
              ({ node }: any, index: number) => {
                const path = pathWithoutSpace(node.title);
                return (
                  <div key={index} className={styles.reportsColumnRight}>
                    <Link to={`/solutions/${path}`}>
                      <GatsbyImage
                        image={node.mainImage.asset.gatsbyImageData}
                        alt={node?.altMainImage || "Reports Main Image"}
                        className={styles.casePreviewImage}
                      />
                      <h3>.{language === "EN" ? node.title : node.titleDe}</h3>
                      <p>
                        {language === "EN" ? node.subtitle : node.subtitleDe}
                      </p>
                      <span>{translate("solutions-read-more")}</span>
                      &nbsp;&nbsp;
                      <img src={buttonRight} alt="Right Button" />
                    </Link>
                  </div>
                );
              }
            )}
          </div>
        </div>

        {pageContext.numberOfReportsPages > 1 && (
          <PagePagination
            pageNumber={pageContext.numberOfReportsPages}
            currentPage={pageContext.currentPage}
            basePathForUrl="/solutions"
          />
        )}
      </div>
    </InnerWrapper>
  );
};

export default SolutionsMain;
