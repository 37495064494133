// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "SolutionsMain-module--aboutMainCircleBlurBlue--x4X0e";
export var aboutMainCircleBlurGreen = "SolutionsMain-module--aboutMainCircleBlurGreen--R1yWW";
export var backgroundCircles = "SolutionsMain-module--backgroundCircles--XsnTR";
export var backgroundCirclesBlogArticles = "SolutionsMain-module--backgroundCirclesBlogArticles--Vlc5E";
export var blogsCircleBlueRight = "SolutionsMain-module--blogsCircleBlueRight--l59yx";
export var casePreviewImage = "SolutionsMain-module--casePreviewImage--NeF9B";
export var contactOutlineCircle = "SolutionsMain-module--contactOutlineCircle--dlpki";
export var fadeInBottomRight = "SolutionsMain-module--fadeInBottomRight--jxswA";
export var reportsColumnLeft = "SolutionsMain-module--reportsColumnLeft--UIfU7";
export var reportsColumnRight = "SolutionsMain-module--reportsColumnRight--M+-jA";
export var reportsContainerWrapper = "SolutionsMain-module--reportsContainerWrapper--sA6bd";
export var reportsMainArticles = "SolutionsMain-module--reportsMainArticles--dzB9V";
export var reportsMainContainer = "SolutionsMain-module--reportsMainContainer--xqbnz";
export var reportsMainTags = "SolutionsMain-module--reportsMainTags--RW9U2";
export var reportsRow = "SolutionsMain-module--reportsRow--8om9q";
export var reportsTag = "SolutionsMain-module--reportsTag--YoA5G";