import React, { FC } from "react";

import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";
import SolutionsMain from "../components/SolutionsUiMain/SolutionsMain";
import SeoImg from "../images/solutions-main.jpg";

type Props = {
  location: any;
  data: any;
  pageContext: any;
};

const Solutions: FC<Props> = ({ data, pageContext }) => {
  return (
    <Layout location="/blog">
      <SEO
        title="Solutions"
        description="Learn more about our cloud-native solutions, expand your knowledge through our exciting workshops, or take advantage of one of our excellent SaaS solutions that will help you and your business move forward."
        type="website"
        image={SeoImg}
      />

      <SolutionsMain data={data} pageContext={pageContext} />
    </Layout>
  );
};

export default Solutions;

export const query = graphql`
  query allSanitySolutionsQuery {
    allSanitySolutions(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          deliverablesSixDescription
          deliverablesTwoDescription
          deliverablesTwoTitleDe
          deliverablesTwoDescriptionDe
          deliverablesTwoTitle
          deliverablesThreeTitleDe
          deliverablesThreeTitle
          deliverablesThreeDescriptionDe
          deliverablesThreeDescription
          deliverablesSixTitleDe
          deliverablesSixTitle
          deliverablesSixDescriptionDe
          calendyLink
          alt
          challengeAlt

          challengeTitle
          challengeTitleDe
          deliverablesFiveDescription
          deliverablesFiveDescriptionDe
          deliverablesFiveTitle
          deliverablesOneTitleDe
          deliverablesOneTitle
          deliverablesOneDescriptionDe
          deliverablesOneDescription
          deliverablesFourTitleDe
          deliverablesFourTitle
          deliverablesFourDescriptionDe
          deliverablesFourDescription
          deliverablesFiveTitleDe
          price
          subtitle
          subtitleDe
          titleDe
          yourSolutionAlt

          yourSolutionTitleDe
          yourSolutionTitle
          yourSolutionImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
            _rawAsset(resolveReferences: { maxDepth: 20 })
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
            _rawAsset(resolveReferences: { maxDepth: 20 })
          }
          challengeImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
            _rawAsset(resolveReferences: { maxDepth: 20 })
          }
        }
      }
    }
  }
`;
